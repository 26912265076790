import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import GrainPattern from "./grain-pattern"
import uuidv4 from "uuid/v4"

const BlobL1 = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()

    return (
      <svg
        ref={ref}
        {...props}
        width="314px"
        height="997.5px"
        viewBox="0 0 314 997.5"
      >
        <GrainPattern id={id} />
        <path
          fill={`url(#${id})`}
          d="M283.5,669.5c-3.8-5.5-4.7-12.4-9.3-17.5c-6.5-7.3-13.1-12.4-16.8-21.8c-3.6-9-7-17.9-8.8-27.5
	c-4-21.6-1.1-44.2,3.1-65.5c2-9.8,6.5-18.3,10-27.6c3.6-9.8,3.1-20.7,3.6-30.9c0.5-10.8,3-20.3,6.5-30.5c3.5-10.3,5.1-21.3,6.4-32.1
	c2.8-22.6,2.9-45.5-3.7-67.4c-6.4-21.3-18.2-41.6-33.1-58c-6.6-7.3-14.7-13.3-22.4-19.4c-7.3-5.8-12.5-12-18.9-18.6
	c-15.2-15.7-33.8-28.1-51-41.5c-8.1-6.3-16.5-12.3-23.2-20.1c-6.4-7.4-11.7-15.6-18.1-23c-5.1-5.9-13.1-7.5-18.9-12.7
	c-6.8-6.1-11.5-14.6-14.5-23.1c-3.1-9-5.4-17.2-10.6-25.2c-5.4-8.3-7.7-17.5-10.6-26.9c-5.6-18.4-11.1-38-23.3-53.3
	C21.5,16.1,11.1,7.6,0,0v997.5c9.9-5.5,19.7-11.1,29.6-16.6c37.3-20.9,74.4-42.4,110.1-66c15.8-10.4,30.7-22.3,47.1-31.8
	c5.9-3.4,10.9-5.6,16.1-10c7-6,13.2-12.7,19.7-19.2c32-32.3,67.5-62,84.6-105.6c7.3-18.6,12.2-44.5-3-60.7
	C298,681,288.6,676.8,283.5,669.5z"
        />
      </svg>
    )
  }
)

export default BlobL1
