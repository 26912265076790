import React, { FunctionComponent } from "react"
import styles from "./catering-konferens.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import LinkButton from "../components/link-button"
import MenuSection, { MenuItem } from "../components/menu-section"
import CateringSuggestion from "../components/catering-suggestion"
import { SplashColor } from "../components/splash"
import SectionTitle, { Title } from "../components/section-title"
import useParallax, { makeTransformY } from "../hooks/use-parallax"
import BlobL1 from "../components/blobs/catering-l-1"
import BlobL2 from "../components/blobs/catering-l-2"
import BlobR1 from "../components/blobs/catering-r-1"
import BlobR2 from "../components/blobs/catering-r-2"
import { graphql } from "gatsby"
import { CateringQuery } from "../generated/graphql"
import renderAst from "../lib/render-ast"
import { oc } from "ts-optchain"

const colors: SplashColor[] = ["pink", "purple", "orange", "red"]

const MenuPage: FunctionComponent<{
  data: CateringQuery
}> = props => {
  if (!props.data.page) {
    throw new Error("Missing page data")
  }
  const { page } = props.data

  const refRBlob1 = useParallax<SVGSVGElement>(null, makeTransformY(-1))
  const refRBlob2 = useParallax<SVGSVGElement>(null, makeTransformY(0.8))
  const refLBlob1 = useParallax<SVGSVGElement>(null, makeTransformY(-0.3))
  const refLBlob2 = useParallax<SVGSVGElement>(null, makeTransformY(0.5))

  return (
    <Layout toggleColor="purple">
      <SEO title={oc(page).title("")} />
      <Hero backgroundColor="pink">
        <h1>{page.title}</h1>
        {renderAst(oc(page).intro.childMarkdownRemark.htmlAst({}))}
        {oc(page).introDownloadAsset.localFile.publicURL() ? (
          <LinkButton to={oc(page).introDownloadAsset.localFile.publicURL("")}>
            {page.introDownloadLabel}
          </LinkButton>
        ) : null}
      </Hero>
      <BlobR1 ref={refRBlob1} className={styles.blobR1} />
      <BlobR2 ref={refRBlob2} className={styles.blobR2} />
      <BlobL1 ref={refLBlob1} className={styles.blobL1} />
      <BlobL2 ref={refLBlob2} className={styles.blobL2} />

      <section className={styles.mainSection}>
        <div className="grid">
          <div className={styles.wideCol}>
            <h2>{page.col1Title}</h2>
            {renderAst(oc(page).col1Text.childMarkdownRemark.htmlAst())}
          </div>
          <div className={styles.wideCol}>
            <h2>{page.col2Title}</h2>
            {renderAst(oc(page).col2Text.childMarkdownRemark.htmlAst())}
          </div>
        </div>
        {false && (
          <>
            {page.menuTitle ? (
              <SectionTitle>
                <Title border>{page.menuTitle}</Title>
              </SectionTitle>
            ) : null}
            {page.menuGroups ? (
              <div className="container">
                <div className={styles.menu}>
                  {oc(page)
                    .menuGroups([])
                    .map((section, i) => {
                      if (!section) {
                        return
                      }
                      return <MenuSection key={i} item={section as MenuItem} />
                    })}
                </div>
              </div>
            ) : null}
            {page.suggestionsTitle ? (
              <SectionTitle id="menu-suggestions">
                <Title border>{page.suggestionsTitle}</Title>
              </SectionTitle>
            ) : null}
            {page.suggestions ? (
              <div className="grid">
                <div className={styles.suggestionsGrid}>
                  {page.suggestions.map((suggestion, i) =>
                    suggestion ? (
                      <CateringSuggestion
                        key={i}
                        color={colors[i % colors.length]}
                        splashClassName={styles.splashClassName}
                        suggestion={suggestion}
                      />
                    ) : null
                  )}
                </div>
              </div>
            ) : null}
          </>
        )}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query Catering($id: String!) {
    page: contentfulMenuPage(id: { eq: $id }) {
      title
      intro {
        childMarkdownRemark {
          htmlAst
        }
      }
      introDownloadLabel
      introDownloadAsset {
        localFile {
          publicURL
        }
      }
      col1Title
      col1Text {
        childMarkdownRemark {
          htmlAst
        }
      }
      col2Title
      col2Text {
        childMarkdownRemark {
          htmlAst
        }
      }
      menuTitle
      menuGroups {
        title
        menuItems {
          title
          description
          price
        }
      }
      suggestionsTitle
      suggestions {
        title
        price
        items {
          id
          title
          details
        }
      }
    }
  }
`

export default MenuPage
