import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import GrainPattern from "./grain-pattern"
import uuidv4 from "uuid/v4"

const BlobR1 = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()

    return (
      <svg
        ref={ref}
        {...props}
        width="481.2px"
        height="1282.2px"
        viewBox="0 0 481.2 1282.2"
      >
        <GrainPattern id={id} />
        <path
          fill={`url(#${id})`}
          d="M336.6,1148.9C336.5,1148.9,336.5,1148.9,336.6,1148.9C336.5,1148.9,336.6,1148.9,336.6,1148.9z"
        />
        <path
          fill={`url(#${id})`}
          d="M481.2,0c-2.6,2.4-5.2,4.8-7.7,7.2c-13,12.1-26.2,24.9-40.1,37.6c-14.4,13.3-29.8,30.5-27.2,31.7
		c-11,8.4-40.8,23.3-53,30.3c-6.8,4-14,7.2-21.4,9.8c2.4-3.5-34.7-1-49.2,0c-21.1,1.4-36.5,8.6-58.6,12.6
		c-8.7,1.6-17.3,3.6-26.2,6.1c-25.1,7.1-49.2,17.1-71.8,30c-12.1,6.9-7.6,6.8-18.8,15.4c-1.6,1.2-19.6,10.4-21.4,11.8
		c-7.2,5.8-16.5,20.4-23.1,27c-26.9,26.7-44.1,49.4-51.3,82.3c-0.5,2.3-1,4.8-1.4,7.3c-1.4,8.9-2.8,17.8-4,26.9
		c-1,24.4-4.2,25.4-5.1,35.8c-1.7,18.9-0.7,32.8,0,51.7c0.3,7.4,3.1,16.5,6.8,25.9c2.1,5.2,4.4,10.5,6.8,15.7
		c3.1,7,6.1,13.6,7.9,18.7c0,0.1,0.2,0.5,0.4,1.3c3.4,10.1,6.4,20.3,8.8,30.7c0.8,3.6,1.6,7.7,2.3,12c1.2,7.2,1.9,15.2,2.6,23.7
		c0.6,8,1.2,16.4,1.8,25.1c0.5,6.5,1.1,13.1,1.9,20c0.1,1.2-0.2,6.5,0,8c0.5-1.9,3,12.9,5.1,21c2.7,10.5,11.6,19,17.2,27
		c4.7,6.5,10.2,12.4,16.2,17.6c7.4,6.6,29.4,14.9,28.2,13.6c18.3,12.3,31.4,26,32.7,26.9c13.8,9.9,25.6,5.1,37.6,19.3
		c7.7,9.1,13.5,23.2,20.3,33.2c6,8.9,11.5,34.3,16.3,43.1c2.6,11.7,6.1,23.1,10.2,34.3c4.7,12.1,18.4,50.3,20,48.8
		c5.2,18.5,6.2,8.1,9.1,32.3c3.5,11.4,13.3,22.9,17.1,34.5c3,9.3,7.2,26.5,10.5,36.1c-0.1,5.4,6.8,24,11.8,37.9
		c7.7,21.4,19.2,38.3,19.9,39.6c0.2,0.5,5.4,19.6,5.7,20.4c5.1,13.1,9.4,29,14.7,42.7c2.1,5.3,3.8,16,3.7,15.9
		c0.3,0.7,3.6,6.5,3.9,7.3c3.8,9.7,3.5,21.8,8,31.1c9.2,19,24.8,27.1,39,42.1c10.6,11.1,21.9,33.1,37,40.9
		c1.9,0.3,32.4,4.9,33.9,4.7c7.2,2.1,11.6,5.4,20.1,6.8c0.9,0.1,1.8,0.3,2.7,0.4V0z"
        />
      </svg>
    )
  }
)

export default BlobR1
