import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import GrainPattern from "./grain-pattern"
import uuidv4 from "uuid/v4"

const BlobR2 = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()

    return (
      <svg
        ref={ref}
        {...props}
        height="899.1px"
        width="290.3px"
        viewBox="0 0 290.3 899.1"
      >
        <GrainPattern id={id} x="60%" />
        <path
          fill={`url(#${id})`}
          d="M290.3,0c-5.5,17.9-11.1,35.7-16.9,53.6c-13.3,41-27.7,84.7-52.2,120.6c-12.3,18.1-27.9,33.3-44.4,47.6
	c-12.9,11.2-23.8,24-35.7,35.4c-19.2,18.2-36.3,28.7-49.4,52.3c-6.1,11-14.8,17.5-23.2,26.9c-13,14.5-14.8,32-23.3,48.8
	c-37,72.5-63.5,158.1-29.6,237.3c12.3,28.7,35,48.7,48.4,76.5c17.1,35.7,32.9,69.9,56,102.5c25.2,35.6,57.9,71.8,100,87.4
	c22.7,8.4,46.4,10.4,70.3,10.3V0z"
        />
      </svg>
    )
  }
)

export default BlobR2
