import React, { FunctionComponent } from "react"
import styles from "./section-title.module.css"
import cx from "classnames"

const SectionTitle: FunctionComponent<{
  className?: string
  id?: string
}> = ({ className, ...rest }) => {
  const props = {
    className: cx(styles.sectionTitle, className),
    ...rest,
  }

  return <div {...props} />
}

export const Title: FunctionComponent<{
  className?: string
  border?: boolean
}> = ({ className, border, ...rest }) => {
  const props = {
    className: cx(styles.title, className, {
      [styles.border]: border,
    }),
    ...rest,
  }

  return <h2 {...props} />
}

export const Subitle: FunctionComponent<{
  className?: string
  border?: boolean
}> = ({ className, border, ...rest }) => {
  const props = {
    className: cx(styles.subtitle, className, {
      [styles.border]: border,
    }),
    ...rest,
  }

  return <div {...props} />
}

export default SectionTitle
