import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import GrainPattern from "./grain-pattern"
import uuidv4 from "uuid/v4"

const BlobL2 = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()

    return (
      <svg
        ref={ref}
        {...props}
        width="403px"
        height="1067px"
        viewBox="0 0 403 1067"
      >
        <GrainPattern id={id} x="40%" />
        <path
          fill={`url(#${id})`}
          d="M257.5,216.3C214,174.1,167.1,135,120.6,96C81.2,62.9,41.3,30.2,0,0v1066.9h0.6c9.4-17.2,19.6-33.9,33.7-47.7
	c26.5-25.9,60.9-44.2,95.3-61.9c61.2-31.6,126.8-62.1,155.3-124.2c27.9-61.1,8.2-146,14.4-218.2c3.3-39.7,17-70.8,47.1-91.4
	c26.7-18.3,55.9-34.6,56.6-73.1C404.2,377,327.7,284.3,257.5,216.3z"
        />
      </svg>
    )
  }
)

export default BlobL2
